.table-export-button-excel {
    background-color: #009800;
    color: #FFFFFF;
}

.table-export-button-excel:hover {
    background-color: #52d352;
    color: #FFFFFF;
}

.table-responsive::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.table-responsive {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.dataTables_wrapper .dataTables_paginate>.paginate_button {
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    border-radius: 50% !important;
    padding: 0;
    font-size: 1rem;
}

.sorting-table-container {
    overflow-x: scroll;
}

.sorting-table-container::-webkit-scrollbar {
    height: 1.25rem !important;
    background-color: var(--white);
    border: 1px solid var(--primary);
    border-radius: 0.25rem;
    ;
}

::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 5px var(--shadow-black);  */
    border-radius: 0.25rem;
}

.sorting-table-container::-webkit-scrollbar-thumb {
    height: 0.5rem !important;
    background-color: var(--primary);
    border: 1px solid var(--white);
    border-radius: 0.25rem;
    cursor: pointer;
}

.sorting-table-container::-webkit-scrollbar-thumb:hover {
    box-shadow: inset 0 0 5px #2b2b2b6d;
}

.color-box {
    border-radius: 0.75em;
    padding: 5px 7px;
    background-color: var(--color-bg);
    color: white;
}

.icon-box {
    width: 4.5em;
    height: 4.5em;
    border-radius: 0.75em;
    border: 1px solid #909090;
    background-color: white;
}

.profile-image-box {
    width: 6em;
    height: 6em;
    border-radius: 0.75em;
    border: 1px solid #909090;
}

.profile-image-box>img {
    border-radius: inherit;
}

.color-box-input {
    height: 5em;
    width: 5em;
    padding: 1em;
}

.error {
    color: red;
}

.h-25-vh {
    height: 25vh !important;
}

.h-50-vh {
    height: 50vh !important;
}

.h-75-vh {
    height: 75vh !important;
}

.h-100-vh {
    height: 100vh !important;
}

.member-list-container {
    overflow-y: scroll;
    overflow-x: none;
}

.list-view-container {
    overflow-y: scroll;
    overflow-x: none;
}

.list-view-container::-webkit-scrollbar {
    display: none;
}

.nomination-list-container {
    height: 100vh;
    background-color: #eeeeee;
    border-radius: 5px;
}


.nomination-list-container::-webkit-scrollbar {
    display: none;
}

.nomination-container {
    border-left: 1px solid #eeeeee;
}

@media only screen and (max-width: 769px) {
    .nomination-list-container {
        height: 75vh !important;
    }

    .nomination-container {
        border-left: 0px;
        margin-top: 15px;
        padding-top: 20px;
        border-top: 1px solid #eeeeee;
    }
}

.agent-member-container-listview,
.member-policy-container-listview,
.company-policy-container-listview,
.reference-container-listview {
    overflow-y: scroll;
    overflow-x: none;
}

.agent-member-container-listview>div:not(:last-child),
.member-policy-container-listview>div:not(:last-child),
.company-policy-container-listview>div:not(:last-child) {
    border-bottom: 1px solid #c1c1c1;
}

.collapse-custom-header-container {
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    border-left: 1px solid #eeeeee;
    border-right: 1px solid #eeeeee;
    border-radius: 0;
}

.collapse-custom-header-container:hover {
    font-weight: 800;
}

.collapse-custom-body-container {
    border-bottom: 1px solid #eeeeee;
    border-left: 1px solid #eeeeee;
    border-right: 1px solid #eeeeee;
}

.collapse-custom-body-container>div>div>div:not(:last-child) {
    border-bottom: 1px solid #c1c1c1;
}

.create-company-policy-container {}

.left-border-only,
.company-policy-listview {
    border-left: 1px solid #eeeeee;
}

@media only screen and (max-width: 1199px) {
    .left-border-only,
    .company-policy-listview {
        border: 0;
    }
}

.questionnaire-list {
    overflow-y: scroll;
    overflow-x: none;
}

.questionnaire-list-container {
    border-top: 1px solid #eeeeee;
}

/* special buttons */
.cancel-button-icon {
    color: #ff0000;
}

.cancel-button-icon:hover {
    color: #ff000073;
}

.move-up-button-icon,
.move-down-button-icon {
    color: #7b7b7b;
    cursor: pointer;
}

.move-up-button-icon:hover,
.move-down-button-icon:hover {
    color: #bcbcbc
}

/* font larger */
.font-25-bigger {
    font-size: 1.25em;
}

.rider-reference-container {
    border-radius: 0.25em;
    border: 1px solid #bcbcbc;
    padding: 5px;
}

@media only screen and (max-width: 87.5rem) {
    .form-control {
        height: 3.5rem;
    }
}

.form-label.required::after {
    content: "*";
    display: inline-block;
    margin-left: .25rem;
    color: #ff0000;
}

.nav-header-logo {
    /* background-color: white; */
    /* border-radius: .75rem; */
    /* box-shadow: 10px -10px 60px 0.5rem;   */
    /* box-shadow: 0 0 50px 10px;   */
}

.nav-header-logo-mobile {
    display: none;
    /* background-color: white; */
    /* border-radius: .75rem; */
    /* box-shadow: 10px -10px 60px 0.5rem;   */
    /* box-shadow: 0 0 50px 10px;   */
}

.menu-toggle .nav-header .nav-header-logo {
    display: none;
}

.menu-toggle .nav-header .nav-header-logo-mobile {
    display: block;
}


@media screen and (max-width: 768px) {
    .nav-header-logo {
        display: none;
        box-shadow: none !important;
    }

    .nav-header-logo-mobile {
        display: block;
    }
}
